/**
 * @name: 赛事订单接口
 * @author: itmobai
 * @date: 2023-06-01 14:51
 * @description：赛事订单接口
 * @update: 2023-06-01 14:51
 */
import {get} from "@/request";
import {IMatchOrder, IMatchOrderParam} from "@/apis/order/match/types";
import {IPageRes} from "@/apis/page";

/**
 * 赛事订单分页查询
 * @param params
 */
export const orderQueryGameOrderByPageApi = (params: IMatchOrderParam) => get<IPageRes<IMatchOrder[]>>("/golf/order/queryGameOrderByPage", params)
/**
 * 赛事订单导出excel
 * @param params
 */
export const orderGameOrderExportApi = (params: IMatchOrderParam) => get("/golf/order/gameOrderExport", params, 'blob')
